import React, {useState, useRef} from "react";
import Axios from "axios";

import "./UploadOrders.scss";

const UploadOrders = (props) => {
	const [dragging, setDraging] = useState(false);
	const [test, setTest] = useState(null);
	const [file, updateFile] = useState(null);
	const [fileConvertError, updateFileConvertError] = useState(null);
	const [fileName, updateFileName] = useState("");
	const [src, updateSrc] = useState(null);
	const [showApprovePopup, setShowApprovePopup] = useState(false);
	const [uploadPending, setUploadPending] = useState({});
	const [uploading, setUploading] = useState(false);

	const fileInput = useRef(null);

	const onSelectFile = (e, drop) => {
		setTest(e.target.value);
		let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
		if (!file) return;
		let reader = new FileReader();

		updateFile(file);
		updateFileConvertError(null);
		updateFileName(file.name);

		reader.readAsDataURL(file);
		reader.onload = (res) => updateSrc(res.target.result);
	};

	const uploadFile = async () => {
		setUploading(true);
		const i = src.indexOf("base64,");
		const buffer = Buffer.from(src.slice(i + 7), "base64");
		const file = new File([buffer], "name", {
			type: "application/vnd.ms-excel",
		});

		const fd = new FormData();
		fd.append("file", file);
		fd.append("filename", file.name);
		let response = await Axios.post("/api/upload-orders", fd); //.then((res) => {});
		updateSrc(undefined);
		updateFileName(undefined);
		updateFile(undefined);
		updateFileConvertError(undefined);
		fileInput.current.value = "";
		setShowApprovePopup(true);
		setUploadPending(response.data.data);
		setUploading(false);
	};

	const confirmOrders = async () => {
		await Axios.put("/api/upload-orders/approve", {
			orders: uploadPending.rcs,
		});
		setShowApprovePopup(false);
	};
	return (
		<div className="df acc page_wrpr">
			<div className="df ffc acc wrpr">
				<div className="inpt_wrpr">
					<input
						className="dn"
						id="upld_file"
						onChange={(e) => onSelectFile(e)}
						type="file"
						ref={fileInput}
					></input>
					<label
						className="df acc upload_area"
						htmlFor="upld_file"
						onDrop={(e) => {
							setDraging(false);
							e.preventDefault();
							onSelectFile(e, true);
						}}
						onDragOver={(e) => {
							setDraging(true);
							e.preventDefault();
						}}
						onDragEnd={() => setDraging(false)}
						onDragLeave={() => setDraging(false)}
					>
						{!fileName ? "Select or drag file" : fileName}
					</label>
				</div>
				<div className="btn_wrpr">
					<button
						className={uploading ? "uploading" : ""}
						disabled={!src || uploading}
						onClick={() => uploadFile()}
					>
						Upload
					</button>
				</div>
			</div>
			{showApprovePopup ? (
				<div className="approve_popup">
					<div className="df acsa hdr">Confirm order upload</div>
					<div className="df ffc acc">
						<p className="tac">
							We found {uploadPending.count} orders
						</p>
						<div>
							<button onClick={() => confirmOrders()}>
								Confirm
							</button>
						</div>
						<div>
							<button
								className="dlt"
								onClick={() => setShowApprovePopup(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			) : undefined}
		</div>
	);
};

export default UploadOrders;

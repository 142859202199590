import React, {Component} from "react";
import Axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import Home from "./containers/home/Home";
import {BrowserRouter, Route, Link, Switch} from "react-router-dom";
import OrderList from "./containers/orders/order-list/OrderList";

import "./App.scss";
import Header from "./containers/header/Header";
import Nav from "./containers/nav/Nav";
import UploadOrders from "./containers/orders/upload-orders/UploadOrders";
import ChatsPage from "./containers/chats/Chats";

// https://warrantybotbe.azurewebsites.net

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
	axiosDefaults.baseURL = "https://warrantybotbe.azurewebsites.net/";
}

class App extends Component {
	onSelectFile = (e, drop) => {
		let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
		if (!file) return;
		let reader = new FileReader();
		this.setState({
			file: file,
			fileConvertError: null,
			fileName: file.name,
		});

		reader.readAsDataURL(file);
		reader.onload = (res) => this.setState({src: res.target.result});
	};

	//     reader.onload = (res) => this.setState({ src: res.target.result });
	//   };

	uploadFile = () => {
		const i = this.state.src.indexOf("base64,");
		const buffer = Buffer.from(this.state.src.slice(i + 7), "base64");
		const file = new File([buffer], "name", {
			type: "application/vnd.ms-excel",
		});

		const fd = new FormData();
		fd.append("file", file);
		fd.append("filename", file.name);
		Axios.post("/api/upload-orders", fd).then((res) => {});
	};

	render() {
		return (
			<BrowserRouter>
				<div
					className="df app_wrpr"
					onDragOver={(e) => {
						e.preventDefault();
					}}
					onDrop={(e) => {
						e.preventDefault();
					}}
				>
					<div className="nav_sec">
						<Nav></Nav>
					</div>

					<div className="page_sec">
						<Header></Header>
						<div className="page_cntnt_sec">
							<Switch>
								<Route
									path="/order-list"
									component={OrderList}
								/>
								<Route
									path="/upload-orders"
									component={UploadOrders}
								></Route>
								<Route
									path="/chats"
									component={ChatsPage}
								></Route>
								<Route path="/" component={Home} />{" "}
							</Switch>
						</div>
					</div>

					{/* <input
					onChange={(e) => this.onSelectFile(e)}
					type="file"
				></input>
				<button onClick={() => this.uploadFile()}>Upload</button> */}
				</div>{" "}
			</BrowserRouter>
		);
	}
}

export default App;

// onSelectFile = (e, drop) => {
//     let file = drop ? e.dataTransfer.files[0] : e.target.files[0];
//     if (!file) return;
//     let reader = new FileReader();
//     this.setState({
//       file: file,
//       fileConvertError: null,
//       fileName: file.name,
//     });

//     reader.readAsDataURL(file);
//     reader.onload = (res) => this.setState({ src: res.target.result });
//   };

// POST

// uploadFile = () => {
//     const i = this.state.src.indexOf("base64,");
//     const buffer = Buffer.from(this.state.src.slice(i + 7), "base64");
//     const file = new File([buffer], "name", {
//       type: "application/vnd.ms-excel",
//     });

//     const fd = new FormData();
//     fd.append("file", file);
//     fd.append("filename", file.name);
// Axios.post("api/upload-file/bank-upload", fd).then((res) => {

import Axios from "axios";
import React, {useEffect, useState} from "react";
import {Link, NavLink, Route} from "react-router-dom";

import "./Chat.scss";
import Conversation from "./conversation/Conversation";

const ChatsPage = (props) => {
	const [list, setList] = useState([]);
	const [current, setCurrent] = useState(undefined);

	const getList = async () => {
		let list = await Axios.get("/api/chat-custmer-list");
		setList(list.data.data);
	};

	useEffect(() => {
		getList();
	}, []);
	return (
		<div className="df chat_page">
			{console.log(current)}
			<div className="list_sec">
				{list.map((itm, idx) => (
					<NavLink
						activeClassName="active"
						className="df acsa"
						to={`/chats/${itm._id}`}
						onClick={() => setCurrent(itm)}
					>
						{itm.phoneNumber}{" "}
						{/* {itm.hasNew ? (
							<p className="mla alert">{itm.hasNew}</p>
						) : undefined} */}
					</NavLink>
				))}
			</div>

			<div className="conversation_sec">
				<Route
					path="/chats/:id"
					render={(props) => (
						<Conversation {...props} current={current} />
					)}
					// component={Conversation}
				></Route>
			</div>
		</div>
	);
};

export default ChatsPage;

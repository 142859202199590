import React, {useState, useEffect, useRef} from "react";
import Axios from "axios";
import _ from "lodash";
import {Route, Link} from "react-router-dom";
import UploadOrders from "../upload-orders/UploadOrders";
import moment from "moment";

import "./OrderList.scss";
import SendIcon from "../../../assats/images/send.png";

const MessagesPopup = (props) => {
	const [messagesList, updateMessagesList] = useState([]);
	const [inputValue, updateInputValue] = useState("");
	const [order, updateOrder] = useState({});
	const [disabled, updateDisabled] = useState(true);

	let refreshInterval = useRef(null);
	let messagesEnd = useRef(null);

	const getOrder = async () => {
		let order = await Axios.get(`/api/order/${props.match.params.id}`);

		updateOrder(order.data.data);
	};
	const getOrderMessages = async (orderId) => {
		let msgs = await Axios.get(`/api/message/${orderId}`);

		// scrollToBottom = () => {

		if (messagesEnd.current)
			setTimeout(() => {
				messagesEnd.current.scrollIntoView({behavior: "smooth"});
			}, 0);
		// };

		updateMessagesList(msgs.data.data);
	};

	const sendMessage = async () => {
		clearInterval(refreshInterval.current);
		let messages = await Axios.post("/api/message", {
			toNumber: order.messagePhoneNumber,
			text: inputValue,
			orderId: props.match.params.id,
		});

		if (messagesEnd.current)
			setTimeout(() => {
				messagesEnd.current.scrollIntoView({behavior: "smooth"});
			}, 0);

		refreshInterval.current = setInterval(() => {
			getOrderMessages(props.match.params.id);
		}, 1000 * 4);

		updateMessagesList(messages.data.data);
		updateInputValue("");
	};

	useEffect(() => {
		if (!props.match.params.id) return;
		getOrder(props.match.params.id);
		getOrderMessages(props.match.params.id);
	}, [props.match.params.id]);

	// const formatDate = date =>

	return (
		<div className="overlay">
			<div className="msg_popup">
				<div className="df acsa pp_hdr">
					<div className="info_wrpr">
						<p>{order.buyerName}</p>
						<p>{order.messagePhoneNumber}</p>
					</div>
					<Link
						to="/order-list"
						className="mla"
						onClick={() => clearInterval(refreshInterval.current)}
					>
						Close
					</Link>
				</div>

				{/* {console.log(props.match.params.id)} */}

				{/* <p onClick={() => clearInterval(refreshInterval.current)}>
				clearInterval
			</p> */}

				<div className="msg_list_wrpr">
					<div className="df ffc msg_list_inner_wrpr">
						{messagesList.map((msg, i) => (
							<>
								<div
									className={`msg_bbl ${
										msg.outgoing ? "out" : "in"
									}`}
								>
									<p key={i}>{msg.text}</p>
								</div>
								<p
									className={`time_stamp ${
										msg.outgoing ? "out" : "in"
									}`}
								>
									{msg.timeStamp
										? moment(msg.timeStamp).format(
												"MM/DD hh:mma"
										  )
										: ""}
								</p>
							</>
						))}

						<div
							style={{float: "left", clear: "both"}}
							ref={messagesEnd}
						></div>
					</div>
				</div>

				<div className="df acsa send_wrpr">
					<textarea
						type="text"
						value={inputValue}
						onChange={(e) => {
							if (e.target.value.length) updateDisabled(false);
							updateInputValue(e.target.value);
						}}
					></textarea>
					<button
						disabled={disabled}
						onClick={async () => {
							if (!inputValue.length || disabled) return;
							console.log("LOL:::");
							updateDisabled(true);
							sendMessage();
						}}
					>
						<img src={SendIcon}></img>
					</button>
				</div>
			</div>
		</div>
	);
};

const OrderList = (props) => {
	const [orderList, updateOrderList] = useState([]);
	const [checked, updateChecked] = useState([]);
	const [posting, updatePosting] = useState(false);
	const [openTab, updateOpenTab] = useState("new");
	const [isLoading, updateIsLoading] = useState(true);
	const [selectedOrderId, updateSelectedOrderId] = useState();
	const [showMsgPopup, updateShowMsgPopup] = useState(false);

	const getOrders = async () => {
		updateIsLoading(true);
		let orders = await Axios.post(`/api/orders-list`, {type: openTab});
		updateOrderList(orders.data.data);
		updateIsLoading(false);
	};

	const updateCheckedAction = (id, isChecked) => {
		console.log(isChecked);

		if (isChecked) updateChecked([...checked, id]);
		// console.log(checked.indexOf(id));
		if (!isChecked) {
			let copy = _.cloneDeep(checked);
			copy.splice(copy.indexOf(id), 1);
			updateChecked([...copy]);
		}
	};

	const createCards = async () => {
		updatePosting(true);
		let res = await Axios.post("/api/create-postcards", {orders: checked});
		if (res.data) {
			updateOrderList(res.data.data);
			updateChecked([]);
			alert("Postcards created");
		} else {
			alert("Error creating postcards");
		}

		updatePosting(false);
	};

	const deleteOrders = async () => {
		updatePosting(true);
		let res = await Axios.delete("/api/orders", {data: {orders: checked}});
		if (res.data) {
			updateOrderList(res.data.data);
			updateChecked([]);
			alert("Postcards deleted");
		} else {
			alert("Error deleting postcards");
		}

		updatePosting(false);
	};

	const selectAll = async (checked) => {
		console.log("Hello all");
		// await updateChecked([]);
		if (checked)
			return updateChecked(
				orderList
					.filter((line) => !line.createdCard)
					.map((line) => line._id)
			);
		return updateChecked([]);
	};
	// /api/orders

	const hasError = (order) => {
		if (
			!order.buyerName.length ||
			!order.addressLine1.length ||
			!order.city.length ||
			!order.stateOrRegion.length ||
			!order.countryCode.length ||
			!order.postalCode.length ||
			!(order.postCardID >= 0)
		)
			return true;

		return false;
	};

	useEffect(() => {
		getOrders();
	}, []);

	useEffect(() => {
		getOrders({type: openTab});
	}, [openTab]);

	return (
		<div className="df ffc page_wrpr">
			{/* orderId */}

			{/* {showMsgPopup ? ( */}
			{/* <MessagesPopup
				orderId={selectedOrderId._id}
				close={() => {
					updateSelectedOrderId(undefined);
					updateShowMsgPopup(false);
				}}
				toNumber={selectedOrderId.messagePhoneNumber}
			></MessagesPopup> */}

			<Route
				path="/order-list/messages/:id"
				component={MessagesPopup}
			></Route>
			{/* ) : undefined} */}
			<div className="df acsa tabs_sec_wrpr">
				<div
					className={`fs14 df acc tab_wrpr ${
						openTab === "new" ? "selected" : ""
					}`}
					onClick={() => updateOpenTab("new")}
				>
					New
				</div>
				<div
					className={`fs14 df acc tab_wrpr ${
						openTab === "mailed" ? "selected" : ""
					}`}
					onClick={() => updateOpenTab("mailed")}
				>
					Mailed
				</div>
				<div
					className={`fs14 df acc tab_wrpr ${
						openTab === "registered" ? "selected" : ""
					}`}
					onClick={() => updateOpenTab("registered")}
				>
					Registered
				</div>
			</div>
			{isLoading ? (
				<>Loading</>
			) : (
				<>
					<div className="df acsa actions_wrpr">
						<div className="sa_wrpr">
							<input
								type="checkbox"
								id="sa"
								onChange={(e) => selectAll(e.target.checked)}
							/>
							<label htmlFor="sa">Select all</label>
						</div>
						<div className="mla">
							<button
								className=""
								disabled={!checked.length || posting}
								onClick={() => createCards()}
							>
								Create cards{" "}
								{checked.length > 0 ? (
									<>
										(
										{checked.length > 0
											? checked.length
											: undefined}
										)
									</>
								) : undefined}
							</button>
							<button
								className="dlt"
								disabled={!checked.length || posting}
								onClick={deleteOrders}
							>
								Delete{" "}
								{checked.length > 0 ? (
									<>
										(
										{checked.length > 0
											? checked.length
											: undefined}
										)
									</>
								) : undefined}
							</button>
						</div>
					</div>
					<div className="order_list">
						{orderList.map((order, idx) => (
							<div
								className={`df acsa order_line ${
									order.registrationStatus === "completed"
										? "done"
										: ""
								} ${hasError(order) ? "error" : ""}`}
								key={`${idx}`}
							>
								<p
									style={{
										position: "absolute",
										left: "10px",
										top: "6px",
										fontSize: "10px",
									}}
								>
									{idx + 1}
								</p>
								<div className="chckbox_wrpr">
									<input
										type="checkbox"
										value={order._id}
										onChange={(e) =>
											updateCheckedAction(
												e.target.value,
												e.target.checked
											)
										}
										checked={
											_.indexOf(checked, order._id) >= 0
										}
									></input>
								</div>
								<div
									className="value_sec"
									style={{width: "8%"}}
								>
									<div className="lbl">Postcard ID</div>
									<div className="val">
										{order.postCardID}
									</div>
								</div>
								<div
									className="value_sec created"
									style={{width: "10%"}}
								>
									<div className="lbl">Postcard Created</div>
									<div className="val">
										{order.createdCard ? (
											<div className="created_icon"></div>
										) : undefined}
									</div>
								</div>
								<div
									className="value_sec"
									style={{width: "10%"}}
								>
									<div className="lbl">Order Number</div>
									<div className="val">
										{order.amazonOrderId}
									</div>
								</div>
								<div
									className="value_sec"
									style={{width: "14%"}}
								>
									<div className="lbl">Address line 1</div>
									<div className="val">
										{order.addressLine1}
									</div>
								</div>
								<div
									className="value_sec"
									style={{width: "8%"}}
								>
									<div className="lbl">Address 2</div>
									<div className="val">
										{order.addressLine2}
									</div>
								</div>
								<div
									className="value_sec"
									style={{width: "12%"}}
								>
									<div className="lbl">City</div>
									<div className="val">{order.city}</div>
								</div>
								<div
									className="value_sec"
									style={{width: "10%"}}
								>
									<div className="lbl">State</div>
									<div className="val">
										{order.stateOrRegion}
									</div>
								</div>
								<div
									className="value_sec"
									style={{width: "16%"}}
								>
									<div className="lbl">Name</div>
									<div className="val">{order.buyerName}</div>
								</div>
								<div
									className="value_sec"
									style={{width: "20%"}}
								>
									<div className="lbl">Email</div>
									<div className="val">
										{order.buyerEmail}
									</div>
								</div>

								<div
									className="value_sec"
									style={{width: "12%"}}
								>
									<div className="lbl">Phone Number</div>
									<div
										className="val"
										// onClick={() => {
										// 	updateSelectedOrderId(order);
										// 	updateShowMsgPopup(true);
										// }}
									>
										<Link
											to={`/order-list/messages/${order._id}`}
										>
											{order.messagePhoneNumber}
										</Link>
									</div>
								</div>

								<div
									className="value_sec"
									style={{width: "12%"}}
								>
									<div className="lbl">Registered</div>
									<div className="val">
										{order.registered === true ? "Yes" : ""}
									</div>
								</div>

								{/* registered */}

								<div
									className="value_sec"
									style={{width: "12%"}}
								>
									<div className="lbl">Satisfied</div>
									<div className="val">
										{order.satisfied === false
											? "Not Satisfied"
											: order.satisfied === true
											? "Satisfied"
											: ""}
									</div>
								</div>
								{/* {order.numberOfItemsUnshipped} */}
								{/* {order._id} */}
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

// amazonOrderId: String,
// addressLine1: String,
// addressLine2: String,
// addressLine3: String,
// city: String,
// municipality: String,
// county: String,
// district: String,
// stateOrRegion: String,
// postalCode: String,
// countryCode: String,
// phone: String,
// addressType: String,
// buyerEmail: String,
// buyerName: String,
// isReplacementOrder: Boolean,
// numberOfItemsUnshipped: Number,
// postCardID: Number,

export default OrderList;

import React from "react";

import "./Nav.scss";
import {Link, NavLink} from "react-router-dom";

const Nav = (props) => (
	<div className="navs_wrpr">
		<div className="df acsa nav_hdr">
			<div className="icon"></div>
			<p>WARRANTY BOT</p>
		</div>
		<NavLink
			to="/order-list"
			activeClassName="selected"
			className="df acsa nav_wrpr orders"
		>
			Order List
		</NavLink>

		<NavLink
			to="/upload-orders"
			activeClassName="selected"
			className="df acsa nav_wrpr upload_orders"
		>
			Upload Orders
		</NavLink>

		<NavLink
			to="/chats"
			activeClassName="selected"
			className="df acsa nav_wrpr chats"
		>
			Chats
		</NavLink>
	</div>
);

export default Nav;

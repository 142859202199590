import Axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import moment from "moment";

import "./Conversation.scss";
import SendIcon from "../../../assats/images/send.png";

const Conversation = (props) => {
	console.log(props);
	const params = useParams();
	const messagesEnd = useRef();
	let refreshInterval = useRef(null);

	const [list, setList] = useState([]);
	const [customer, setCustomer] = useState({});
	const [loading, setLoading] = useState(true);
	const [inputValue, updateInputValue] = useState("");
	const [disabled, updateDisabled] = useState(false);

	const getCustomer = async () => {
		let customer = await Axios.get(`/api/chat-customer/${params.id}`);

		setCustomer(customer.data.data);
	};

	const getConversation = async () => {
		let list = await Axios.get(`/api/customer-chat/${params.id}`);
		setList(list.data.data);

		if (messagesEnd.current)
			setTimeout(() => {
				messagesEnd.current.scrollIntoView({behavior: "smooth"});
			}, 0);
		setLoading(false);
	};

	useEffect(() => {
		if (!params.id) return;
		getCustomer();
		getConversation();
	}, [params.id]);

	const sendMessage = async () => {
		clearInterval(refreshInterval.current);
		let messages = await Axios.post("/api/chat-message", {
			toNumber: customer.phoneNumber,
			text: inputValue,
			customerId: params.id,
		});

		if (messagesEnd.current)
			setTimeout(() => {
				messagesEnd.current.scrollIntoView({behavior: "smooth"});
			}, 0);

		getConversation(params.id);

		setList(messages.data.data);
		updateInputValue("");
	};

	return (
		<div className="df ffc  conversation_wrpr">
			{/* Conversation wrapper {params.id} */}
			<div className="df ffc msg_list_inner_wrpr">
				{!loading
					? list.map((line) => (
							<>
								<div
									className={`msg_bbl ${
										line.outgoing ? "out" : "in"
									}`}
								>
									{line.text}
								</div>
								<p
									className={`timestamp ${
										line.outgoing ? "out" : "in"
									}`}
								>
									{moment(line.timeStamp).format(
										"MMM/DD/YYYY hh:mma"
									)}
								</p>
							</>
					  ))
					: "loading"}
				<div
					style={{float: "left", clear: "both"}}
					ref={messagesEnd}
				></div>
			</div>
			{/* ******************** */}
			<div className="df acsa send_wrpr">
				<textarea
					type="text"
					value={inputValue}
					onChange={(e) => {
						if (e.target.value.length) updateDisabled(false);
						updateInputValue(e.target.value);
					}}
				></textarea>
				<button
					disabled={disabled}
					onClick={async () => {
						if (!inputValue.length || disabled) return;
						console.log("LOL:::");
						updateDisabled(true);
						sendMessage();
					}}
				>
					<img src={SendIcon}></img>
				</button>
			</div>
			{/* ******************** */}
		</div>
	);
};

export default Conversation;
